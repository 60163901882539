"use strict";
jQuery(window).on("beforeunload", function () {
  jQuery(window).scrollTop(0);
});
jQuery(document).ready(function ($) {
  jQuery(window).scrollTop(0);
  $(".site-header.grid-container.fluid .site-branding img").css("opacity", 0);
  //Hit bottom once var

  $("html").addClass("locked");

  if (window.innerWidth > 768) {
    $("#splash-screen").css("cursor", 'url("/intro_cursor.png"), auto');

    $("#splash-screen").on("click touchstart", function () {
      $("#splash-screen").css("cursor", "auto");
      anime({
        duration: 1000,
        easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
        targets: ["#splash-screen"],
        opacity: 0,
        complete: function () {
          anime({
            targets: [
              ".site-header.grid-container.fluid .site-branding img",
              ".glazenhuis.section-one .info-section",
              ".glazenhuis.section-one .one .more-info",
              ".glazenhuis.section-one .one .info-section .title",
              ".glazenhuis.section-one #glazenhuis-bg",
            ],
            opacity: 1,
          });
          $("#splash-screen").remove();
          $("html").addClass("locked");
        },
      });
    });

    //MORE INFO BUTTON CLICK
    $(".glazenhuis.section-one .more-info").on("click touchstart", function () {
      allowScrolling = false;
      var tl = anime
        .timeline({
          duration: 1000,
          easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
        })

        .add(
          {
            targets: [".glazenhuis.section-one .one .info-section"],
            translateY: -250,
          },
          "-=200"
        )
        .add(
          {
            targets: [".glazenhuis.section-one .two .video-wrapper"],
            opacity: 1,
          },
          "-=800"
        )
        .add(
          {
            targets: [".zzip.section-one .two .info-section"],
            right: "-100%",
          },
          "-=900"
        )
        .add(
          {
            targets: [".glazenhuis.section-one .one .more-info"],
            translateY: 250,
            complete: function (anim) {
              $(".glazenhuis.section-one .more-info").remove();
              $(".glazenhuis.section-one .next-project").remove();

              $(".glazenhuis.section-one .one .subtitle").css("opacity", "1");

              $("html").removeClass("locked");
              $("html, body").animate(
                { scrollTop: "+=150px" },
                1000,
                function () {
                  allowScrolling = true;
                }
              );
            },
          },
          "-=900"
        );
    });
  } else if (window.innerWidth < 769) {
    //SPLASH SCREEN ANIMATION

    anime({
      duration: 1000,
      easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
      delay: 1000,
      targets: ["#splash-screen #intro-logo #donkeycomm-logo"],
      opacity: 1,
      complete: function (anim) {
        console.log("welcome");

        $("#splash-screen #view-page").css("opacity", "1");

        $("#splash-screen").css("cursor", 'url("/intro_cursor.png"), auto');
        $("#splash-screen").on("click touchstart", function () {
          $("#splash-screen").css("cursor", "auto");
          anime({
            duration: 1000,
            easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
            targets: ["#splash-screen"],
            opacity: 0,
            complete: function () {
              anime({
                targets: [
                  ".site-header.grid-container.fluid .site-branding img",
                  ".glazenhuis.section-one .info-section",
                  ".glazenhuis.section-one .one .more-info",
                  ".glazenhuis.section-one .one .info-section .title",
                  ".glazenhuis.section-one #glazenhuis-bg",
                  ".glazenhuis.section-one .two .video-wrapper",
                ],
                opacity: 1,
              });
              $("#splash-screen").remove();
              $("html").removeClass("locked");
            },
          });
        });
      },
    });

    //MORE INFO BUTTON CLICK
    $(".glazenhuis.section-one .more-info").on("click touchstart", function () {
      allowScrolling = false;
      var tl = anime.timeline({
        duration: 1000,
        easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
      });
      tl.add(
        {
          targets: [".glazenhuis.section-one .two .info-section"],
          right: "-100%",
        },
        "-=400"
      ).add(
        {
          targets: [".glazenhuis.section-one .one .more-info"],
          translateY: 450,
          complete: function (anim) {
            $(".glazenhuis.section-one .more-info").remove();
            $(".glazenhuis.section-one .next-project").remove();
            $(".glazenhuis.section-one .one .subtitle").css("height", "auto");
            $(".glazenhuis.section-one .one .subtitle").css("opacity", "1");
            $(".glazenhuis.section-one").css("height", "auto");
            $("html").removeClass("locked");
            $("html, body").animate(
              { scrollTop: "+=150px" },
              1000,
              function () {
                allowScrolling = true;
              }
            );
          },
        },
        "-=400"
      );
    });
  }

  var hitBottomOnce5 = 0;
  var allowScrolling = true;
  jQuery("div").on("mousewheel touchmove", function () {
    if (allowScrolling) {
      return true;
    } else {
      return false;
    }
  });

  $(".glazenhuis.section-one").css("width", "auto");
  $(".grid-x").css("width", "100%");

  //GET REST OF THE PAGE
  function fetchGlazenhuis() {
    $.ajax({
      url: "/glazenhuis/",
      type: "GET",
      success: function (data) {
        $("#next-project").load(
          "/glazenhuis .glazenhuis.the-rest,.hellovelo.section-one",
          function () {
            $(this).children(":first").unwrap();
            window.sr3 = ScrollReveal();
            sr3.reveal(".glazenhuis.the-rest,.hellovelo.section-one", {
              duration: 500,
              distance: "0px",
              scale: 1,
              delay: 100,
            });
            sr3.reveal(
              ".str",
              {
                duration: 500,
                origin: "bottom",
                scale: 1,
              },
              70
            );
            $("html, body").animate(
              { scrollTop: "+=150px" },
              1000,
              function () {
                allowScrolling = true;
              }
            );
            $(".hellovelo.section-one").after('<div id="next-project"></div>');
          }
        );
      },
    });
  }

  //Click next project
  $(".glazenhuis.section-one .next-project").on(
    "click touchstart",
    function () {
      $(this).remove();
      allowScrolling = false;
      //$('.glazenhuis.section-one').wrap("<div id='next-project-top'></div>");

      hitBottomOnce5 = 1;
      fetchRightHelloVelo();
    }
  );
  function fetchRightHelloVelo() {
    $.ajax({
      url: "/hellovelo/",
      type: "GET",
      async: false,
      success: function (data) {
        $("#next-project-top").append(
          $('<div class="inflwrap">').load(
            "/hellovelo .hellovelo.section-one",
            function () {
              $(".hellovelo.section-one video").css("opacity", 1);
              $(".inflwrap").children(":first").unwrap();
              $("#next-project-top").css({ display: "flex", width: "200vw" });
              $(".glazenhuis.section-one").css("width", "100vw");
              $(".hellovelo.section-one").css("width", "100vw");
              $(".rvl").css("opacity", "1");
              $(".rvl").removeAttr("data-sr-id");
              moveAway();
            }
          )
        );
      },
    });
  }
  function moveAway() {
    var tl = anime
      .timeline({
        duration: 1000,
        easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
      })
      .add({
        targets: ["#next-project-top"],
        left: "-100vw",
        complete: function () {
          $(".hellovelo.bottom").remove();
          $(".glazenhuis").remove();
          $("#next-project-top").children(":first").unwrap();
          $(".hellovelo.section-one").css("width", "auto");
          $.getScript(
            "/wp-content/themes/digitaldonkey/assets/js/page-specific/dist/hellovelo.min.js",
            function (data, textStatus, jqxhr) {}
          );
          var tl = anime.timeline({
            duration: 1000,
            easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
          });

          allowScrolling = true;
        },
      });
  }

  //On hit bottom window

  window.onscroll = function (ev) {
    if (
      hitBottomOnce5 == 0 &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 15
    ) {
      hitBottomOnce5 = 1;
      allowScrolling = false;
      var scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;

      $("#next-project-top").remove();

      var tl = anime.timeline({
        duration: 1000,
        easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
      });
      tl.add({
        targets: ".hellovelo.section-one .img",
        minHeight: "100vh",
        begin: function () {
          $(".hellovelo.section-one .img").css("overflow", "visible");
          $(".hellovelo.section-one .img").css("position", "unset");
          $(".hellovelo.section-one .one .info-section").css(
            "visibility",
            "visible"
          );
          $(".hellovelo.section-one .one .subtitle").css("height", "auto");
        },
        duration: 1,
      }).add({
        targets: scrollElement,
        scrollTop: document.body.scrollHeight,
        easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
        complete: function (anim) {
          anim.pause();
          $(
            ".glazenhuis.section-one,.glazenhuis.the-rest,.hellovelo.section-one .intro,.hellovelo.section-one .more-info,.hellovelo.section-one .two .info-section"
          ).remove();

          var tl = anime.timeline({
            duration: 1000,
            easing: "cubicBezier(0.680, 0.005, 0.260, 1.000)",
          });

          if (screen.width > 768) {
            tl.add(
              {
                targets: [".hellovelo.section-one .one .info-section"],
                translateY: -100,
              },
              "-=800"
            );
            tl.add(
              {
                targets: [
                  ".hellovelo.section-one .one .info-section .title",
                  ".hellovelo.section-one .one .info-section .subtitle",
                ],

                opacity: 1,
                complete: function (anim) {
                  $(".hellovelo.section-one .one .subtitle").css(
                    "opacity",
                    "1"
                  );
                  $("html").removeClass("locked");
                  fetchHelloVelo();
                },
              },
              "-=800"
            );
          } else {
            tl.add(
              {
                targets: [
                  ".hellovelo.section-one .one .info-section .title",
                  ".zhellovelozip.section-one .one .info-section .subtitle",
                  ".hellovelo.section-one .one img",
                  ".hellovelo.section-one .two img",
                ],
                opacity: 1,
                complete: function (anim) {
                  $(".hellovelo.section-one .one .subtitle").css(
                    "opacity",
                    "1"
                  );
                  $("html").removeClass("locked");
                  fetchHelloVelo();
                },
              },
              "-=800"
            ).add({
              targets: [".hellovelo.section-one.bottom .img"],
              minHeight: "50vh",
            });
          }
        },
      });
    }
  };
  //GET REST OF THE PAGE
  function fetchHelloVelo() {
    $.ajax({
      url: "/hellovelo/",
      type: "GET",
      success: function (data) {
        $("#next-project").load(
          "/hellovelo .hellovelo.the-rest,.lommel.section-one",
          function () {
            $(this).children(":first").unwrap();
            $.getScript(
              "/wp-content/themes/digitaldonkey/assets/js/page-specific/dist/hellovelo.min.js",
              function (data, textStatus, jqxhr) {}
            );
            window.sr3 = ScrollReveal();
            sr3.reveal(".hellovelo.the-rest,.lommel.section-one", {
              duration: 500,
              distance: "0px",
              scale: 1,
              delay: 100,
            });
            $(".lommel.section-one").after('<div id="next-project"></div>');
            $(".hellovelo.section-three video").replaceWith(
              '<video width="100%" height="auto" playsinline="" src="/projects/hellovelo/HelloVelo_vergelijk.mp4" autoplay="autoplay" muted="" loop="" type="video/mp4">Your browser does not support the video tag</video>'
            );
            allowScrolling = true;
          }
        );
      },
    });
  }
});
